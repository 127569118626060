export const state = () => ({
  blackfriday: false,
  noscroll: false,
  scrolled: false,
  hasTopBar: false,
  isLogged: false,
  zIndexOff: false,
  mobileSearchOpen: false,
  mobileMenuOpen: false,
  mobileSubMenuOpen: false,
  modalOpen: false,
  rulesOpen: false,
  rules: {
    title: '',
    text: ''
  },
  LoadingPublic: false,
  msgLoadingPublic: ''
})

export const getters = {
  ItsBlackfriday(state) {
    return state.blackfriday
  },
  zIndexOff(state) {
    return state.zIndexOff
  },
  loadingpublic(state) {
    return state.LoadingPublic
  },
  msgloadingpublic(state) {
    return state.msgLoadingPublic
  },
  scrolled(state) {
    return state.scrolled
  },
  noscroll(state) {
    return state.noscroll
  },
  GGisLogged(state) {
    return state.isLogged
  },
  GGmobileSearchOpen(state) {
    return state.mobileSearchOpen
  },
  GGmobileMenuOpen(state) {
    return state.mobileMenuOpen
  },
  GGmobileSubMenuOpen(state) {
    return state.mobileSubMenuOpen
  },
  GGmodalOpen(state) {
    return state.modalOpen
  },
  GGrulesOpen(state) {
    return state.rulesOpen
  },
  GGRules(state) {
    return state.rules
  }
}

export const mutations = {
  MMzIndexOff(state, zIndexOff) {
    state.zIndexOff = zIndexOff
  },
  MMLoadingPublic(state, LoadingPublic) {
    state.LoadingPublic = LoadingPublic
  },
  MMmsgLoadingPublic(state, msgLoadingPublic) {
    state.msgLoadingPublic = msgLoadingPublic
  },
  MMscrolled(state, scrolled) {
    state.scrolled = scrolled
  },
  MMnoScroll(state, noscroll) {
    state.noscroll = noscroll
  },
  MMmobileSearchOpen(state, mobileSearchOpen) {
    state.mobileSearchOpen = mobileSearchOpen
  },
  MMmobileMenuOpen(state, mobileMenuOpen) {
    state.mobileMenuOpen = mobileMenuOpen
  },
  MMmobileSubMenuOpen(state, mobileSubMenuOpen) {
    state.mobileSubMenuOpen = mobileSubMenuOpen
  },
  MMisLogged(state, isLogged) {
    state.isLogged = isLogged
  },
  MMmodalOpen(state, modalOpen) {
    state.modalOpen = modalOpen
  },
  MMrulesOpen(state, rulesOpen) {
    state.rulesOpen = rulesOpen
  },
  MMrulesTitle(state, title) {
    state.rules.title = title
  },
  MMrulesText(state, text) {
    state.rules.text = text
  }
}

export const actions = {
  AAzIndexOff({ commit}, zIndexOff) {
    commit('MMzIndexOff', zIndexOff)
  },
  AALoadingPublic({ commit }, LoadingPublic) {
    commit('MMLoadingPublic', LoadingPublic)
  },
  AAmsgLoadingPublic({ commit }, msgLoadingPublic) {
    commit('MMmsgLoadingPublic', msgLoadingPublic)
  },
  AAscrolled({ commit }, scrolled) {
    commit('MMscrolled', scrolled)
  },
  AANoScroll({ commit }, noscroll) {
    commit('MMnoScroll', noscroll)
  },
  AAmobileSearchOpen({ commit }, mobileSearch) {
    commit('MMmobileSearchOpen', mobileSearch)
  },
  AAmobileMenuOpen({ commit }, mobileMenu) {
    commit('MMmobileMenuOpen', mobileMenu)
  },
  AAmobileSubMenuOpen({ commit }, mobileSubMenu) {
    commit('MMmobileSubMenuOpen', mobileSubMenu)
  },
  AAisLogged({ commit }, isLogged) {
    commit('MMisLogged', isLogged)
  },
  AAmodalOpen({ commit }, modalOpen) {
    commit('MMmodalOpen', modalOpen)
  },
  AArulesOpen({ commit }, rulesOpen) {
    commit('MMrulesOpen', rulesOpen)
  },
  AArulesTitle({ commit }, title) {
    commit('MMrulesTitle', title)
  },
  AArulesText({ commit }, text) {
    commit('MMrulesText', text)
  }
}
