export const state = () => ({
  logged: false,
  modalOpen: false,
  actionLogin: 'login',
  customer: ''
})

export const getters = {
  GGlogged(state) {
    return state.logged
  },
  modalOpen(state) {
    return state.modalOpen
  },
  GGactionLogin(state) {
    return state.actionLogin
  },
  GGcustomer(state) {
    return state.customer
  }
}

export const mutations = {
  MMlogged(state, logged) {
    state.logged = logged
  },
  MMmodalOpen(state, modalOpen) {
    state.modalOpen = modalOpen
  },
  MMactionLogin(state, actionLogin) {
    state.actionLogin = actionLogin
  },
  MMcustomer(state, customer) {
    state.customer = customer
  }
}

export const actions = {
  AAlogged({ commit }, logged) {
    commit('MMlogged', logged)
  },
  AAmodalOpen({ commit }, modalOpen) {
    commit('MMmodalOpen', modalOpen)
  },
  AAactionLogin({ commit }, actionLogin) {
    commit('MMactionLogin', actionLogin)
  },
  AAcustomer({ commit }, customer) {
    commit('MMcustomer', customer)
  }
}
