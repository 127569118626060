export default ({ app }) => {
  if (process.client) {
    const checkAndFireEvent = () => {
      if (typeof window.$TB === 'undefined') {
        // console.warn('$TB is not loaded yet')
        setTimeout(checkAndFireEvent, 100)
        return
      }
      if (window.$TB.hooks && typeof window.$TB.hooks.fireEvent === 'function') {
        window.$TB.hooks.fireEvent('pageChange', null, { sendBeacon: true })
      } else {
        // console.warn('TB.hooks.fireEvent is not available')
        setTimeout(checkAndFireEvent, 100)
      }
    }
    app.router.afterEach(() => {
      checkAndFireEvent()
    })
  }
}
