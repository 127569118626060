

export default {
  name: 'Error',
  data() {
    return {
      productPage: false
    }
  },
  head() {
    return {
      meta: [
        {
          name:'robots',
          content:'noindex, nofollow'
        }
      ]
    }
  },
  mounted() {
    if (this.$route.path.indexOf('/produto') > -1) {
      this.productPage = true
    }
    setTimeout(() => {
      location.assign('/')
    }, 7000)
    if (window.dataLayer === undefined || window.dataLayer !== undefined) {
      if (localStorage.adobe_analytics_launch_state === '0' || localStorage.adobe_analytics_launch_state === '1') {
        this.$gtm.push({
          event: 'AdobeAnalytics',
          adobeAnalytics: {
            pageType: `Error`,
            pageFirstLevel: `Error`
          }
        })
        if (localStorage.adobe_analytics_launch_state === '0') {
          localStorage.setItem('adobe_analytics_launch_state', 1)
        }
        if (localStorage.adobe_analytics_launch_state === '1') {
          localStorage.setItem('adobe_analytics_launch_state', 2)
        }
      }
    }
  }
}
