export const state = () => ({
  isActive: false,
  isMenu: false,
  isMenuShow: false,
  customZIndex: false,
  customTop: false,
  fullMode: false
})

export const mutations = {
  showOverlay (state) {
    state.isActive = true
  },
  hideOverlay (state) {
    state.isActive = false
  },
  setIsMenu (state, isMenu) {
    state.isActive = isMenu
    state.isMenu = isMenu
  },
  setIsMenuShow (state, isMenuShow) {
    state.isMenuShow = isMenuShow
  },
  clearMenuClass (state) {
    state.isMenu = false
    state.isMenuShow = false
  },
  customZIndex (state, customZIndex) {
    state.customZIndex = customZIndex
  },
  customTop (state, customTop) {
    state.customTop = customTop
  },
  setFullMode (state) {
    state.fullMode = true
  },
  unsetFullMode (state) {
    state.fullMode = false
  }
}

export const getters = {
  isActive (state) {
    return state.isActive
  },
  isMenu (state) {
    return state.isMenu
  },
  isMenuShow (state) {
    return state.isMenuShow
  },
  customZIndex (state) {
    return state.customZIndex
  },
  customTop (state) {
    return state.customTop
  },
  fullMode (state) {
    return state.fullMode
  }
}
