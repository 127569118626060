export const state = () => ({
  modalOpenCart: false,
  cartQTY: null,
  cartItems: ''
})

export const getters = {
  cartQTY(state) {
    return state.cartQTY
  },
  modalOpenCart(state) {
    return state.modalOpenCart
  },
  cartItems(state) {
    return state.cartItems
  }
}

export const mutations = {
  MMcartQTY(state, cartQTY) {
    state.cartQTY = cartQTY
  },
  MMmodalOpenCart(state, modalOpenCart) {
    state.modalOpenCart = modalOpenCart
  },
  MMcartItems(state, cartItems) {
    state.cartItems = cartItems
  }
}

export const actions = {
  AAcartQTY({ commit }, cartQTY) {
    commit('MMcartQTY', cartQTY)
  },
  AAmodalOpenCart({ commit }, modalOpenCart) {
    commit('MMmodalOpenCart', modalOpenCart)
  },
  AAcartItems({ commit }, cartItems) {
    commit('MMcartItems', cartItems)
  }
}
