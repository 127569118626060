export const state = () => ({
  lens: {
    dominance: '',
    sphere: '',
    addition: '',
    cylinder: '',
    axis: '',
    baseCurve: '',
    lensesColor: '',
    id: ''
  },
  lensOD: {
    dominance: '',
    sphere: '',
    addition: '',
    cylinder: '',
    axis: '',
    baseCurve: '',
    lensesColor: '',
    id: ''
  }
})
export const getters = {
  lens: (state) => {
    return state.lens
  },
  lensOD: (state) => {
    return state.lensOD
  }
}

export const mutations = {
  MMlensDominance(state, dominance) {
    state.lens.dominance = dominance
  },
  MMlensSphere(state, sphere) {
    state.lens.sphere = sphere
  },
  MMlensAddition(state, addition) {
    state.lens.addition = addition
  },
  MMlensCylinder(state, cylinder) {
    state.lens.cylinder = cylinder
  },
  MMlensAxis(state, axis) {
    state.lens.axis = axis
  },
  MMlensBaseCurve(state, baseCurve) {
    state.lens.baseCurve = baseCurve
  },
  MMlensLensesColor(state, lensesColor) {
    state.lens.lensesColor = lensesColor
  },
  MMlensId(state, id) {
    state.lens.id = id
  },
  MMlensDominanceOD(state, dominance) {
    state.lensOD.dominance = dominance
  },
  MMlensSphereOD(state, sphere) {
    state.lensOD.sphere = sphere
  },
  MMlensAdditionOD(state, addition) {
    state.lensOD.addition = addition
  },
  MMlensCylinderOD(state, cylinder) {
    state.lensOD.cylinder = cylinder
  },
  MMlensAxisOD(state, axis) {
    state.lensOD.axis = axis
  },
  MMlensBaseCurveOD(state, baseCurve) {
    state.lensOD.baseCurve = baseCurve
  },
  MMlensLensesColorOD(state, lensesColor) {
    state.lensOD.lensesColor = lensesColor
  },
  MMlensIdOD(state, id) {
    state.lensOD.id = id
  }
}

export const actions = {
  AAlensDominance({ commit }, dominance) {
    commit('MMlensDominance', dominance)
  },
  AAlensSphere({ commit }, sphere) {
    commit('MMlensSphere', sphere)
  },
  AAlensAddition({ commit }, addition) {
    commit('MMlensAddition', addition)
  },
  AAlensCylinder({ commit }, cylinder) {
    commit('MMlensCylinder', cylinder)
  },
  AAlensAxis({ commit }, axis) {
    commit('MMlensAxis', axis)
  },
  AAlensBaseCurve({ commit }, baseCurve) {
    commit('MMlensBaseCurve', baseCurve)
  },
  AAlensLensesColor({ commit }, lensesColor) {
    commit('MMlensLensesColor', lensesColor)
  },
  AAlensId({ commit }, id) {
    commit('MMlensId', id)
  },
  AAlensDominanceOD({ commit }, dominance) {
    commit('MMlensDominanceOD', dominance)
  },
  AAlensSphereOD({ commit }, sphere) {
    commit('MMlensSphereOD', sphere)
  },
  AAlensAdditionOD({ commit }, addition) {
    commit('MMlensAdditionOD', addition)
  },
  AAlensCylinderOD({ commit }, cylinder) {
    commit('MMlensCylinderOD', cylinder)
  },
  AAlensAxisOD({ commit }, axis) {
    commit('MMlensAxisOD', axis)
  },
  AAlensBaseCurveOD({ commit }, baseCurve) {
    commit('MMlensBaseCurveOD', baseCurve)
  },
  AAlensLensesColorOD({ commit }, lensesColor) {
    commit('MMlensLensesColorOD', lensesColor)
  },
  AAlensIdOD({ commit }, id) {
    commit('MMlensIdOD', id)
  }
}
