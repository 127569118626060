
import { mapGetters, mapActions } from 'vuex'
import Customer from '~/apollo/queries/customer'
import consultLGPD from '~/apollo/queries/consultLGPD'
import createCookiesLGPD from '~/apollo/mutation/createCookiesLGPD'
import lgpdLogConsent from '~/apollo/mutation/consentLGPD'
import storeConfigE3 from '~/apollo/queries/storeConfigE3'

export default {
  name: 'Default',
  components: {
    // HeaderElens: () => import('@/components/Header/Header'),
    // TopBar: () => import('@/components/Header/VHeader-top-bar'),
    // FooterElens: () => import('@/components/Footer/Footer'),
    // NewFooterElens: () => import('@/components/new-Footer/NFooter'),
    LoadingPublic: () => import('@/components/globals/Loading-public')
  },
  data() {
    return {
      showLGPD: false,
      topbar: '',
      hasTopbar: false,
      lgpd: {
        lgpd_popup_text: '',
        lgpd_popup_accept_button: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      noscroll: 'layout/noscroll',
      ItsBlackfriday: 'layout/ItsBlackfriday'
    })
  },
  head() {
    return {
      bodyAttrs: {
        class: this.noscroll ? 'noscroll' : ''
      },
      htmlAttrs: {
        class: this.noscroll ? 'noscroll' : '',
        lang: 'pt-BR'
      }
    }
  },
  beforeMount() {
    if (localStorage.current_customer_token) {
      this.getCustomer()
    }
    if (!localStorage.current_store) {
      localStorage.setItem('current_store', 'elens')
    }
    this.getStoreConfigE3()
  },
  mounted() {
    this.createCookies()
    if (typeof this.$route.query.utm_source !== 'undefined') {
      this.$cookies.set('utm_source', this.$route.query.utm_source, Infinity, '/')
    } else if (this.$cookies.get('utm_source') !== 'whatsapp') {
      this.$cookies.set('utm_source', '(direct)', Infinity, '/')
    }
    if (typeof this.$route.query.utm_medium !== 'undefined') {
      this.$cookies.set('utm_medium', this.$route.query.utm_medium, Infinity, '/')
    } else if (this.$cookies.get('utm_source') !== 'whatsapp') {
      this.$cookies.set('utm_medium', '(none)', Infinity, '/')
    }
    if (typeof this.$route.query.utm_campaign !== 'undefined') {
      this.$cookies.set('utm_campaign', this.$route.query.utm_campaign, Infinity, '/')
    } else if (this.$cookies.get('utm_source') !== 'whatsapp') {
      this.$cookies.set('utm_campaign', '(none)', Infinity, '/')
    }
    const expires = new Date()
    expires.setTime(expires.getTime() + (24 * 60 * 60 * 1000))
    if (typeof this.$route.query.atendimento !== 'undefined') {
      this.$cookies.set('utm_source', 'whatsapp', expires, '/')
      this.$cookies.set('utm_medium', 'link', expires, '/')
      this.$cookies.set('utm_campaign', this.$route.query.atendimento, expires, '/')
    }
  },
  methods: {
    ...mapActions({
      AAlogged: 'login/AAlogged',
      NameCustomer: 'login/AAcustomer'
    }),
    async getStoreConfigE3() {
      await this.$apollo.query({
        query: storeConfigE3,
      }).then(data => {
        localStorage.setItem('show_cross_sell_products', data.data.storeConfigE3.show_cross_sell_products)
        localStorage.setItem('show_coupon_field', data.data.storeConfigE3.show_coupon_field)
        localStorage.setItem('cashback_enabled', data.data.storeConfigE3.cashback_enabled)
        if (data.data.storeConfigE3.socialLoginConfig.module_enabled === '1' || data.data.storeConfigE3.socialLoginConfig.module_enabled === 'true') {
          localStorage.setItem('social_google_enabled', data.data.storeConfigE3.socialLoginConfig.google_enabled)
          localStorage.setItem('social_facebook_enabled', data.data.storeConfigE3.socialLoginConfig.facebook_enabled)
        } else {
          localStorage.setItem('social_google_enabled', '0')
          localStorage.setItem('social_facebook_enabled', '0')
        }
        localStorage.setItem('active_adobe_analytics', data.data.storeConfigE3.active_adobe_analytics)
        localStorage.setItem('adobe_analytics_account', data.data.storeConfigE3.adobe_analytics_account_id)
        localStorage.setItem('adobe_analytics_tracking', data.data.storeConfigE3.adobe_analytics_tracking_server_url)
        if (!localStorage.adobe_analytics_launch_state) {
          localStorage.setItem('adobe_analytics_launch_state', 0)
        }
      }).catch(({ graphQLErrors }) => {
        console.error(graphQLErrors)
      })
    },
    async consultGeneralLGPD() {
      await this.$apollo.query({
        query: consultLGPD
      }).then((data) => {
        this.lgpd.lgpd_popup_text = data.data.storeConfig.lgpd_popup_text
        this.lgpd.lgpd_popup_accept_button = data.data.storeConfig.lgpd_popup_accept_button
        this.showLGPD = true
      }).catch(({ graphQLErrors }) => {
      })
    },
    async createCookies() {
      await this.$apollo.mutate({
        mutation: createCookiesLGPD,
        variables: {
          cookies: this.$cookies.keys()
        },
        context: {
          headers: {
            authorization: `bearer ${localStorage.current_customer_token}`
          }
        }
      }).then((data) => {
      }).catch(({ graphQLErrors }) => {
        console.log(graphQLErrors[0].message)
      })
      if (!this.$cookies.isKey('lgpd_cookies')) {
        await this.consultGeneralLGPD()
      }
    },
    acceptLGPD() {
      this.$cookies.set('lgpd_cookies', true, '1y')
      this.showLGPD = false
    },
    async getCustomer() {
      await this.$apollo.query({
        query: Customer,
        context: {
          headers: {
            authorization: `bearer ${localStorage.current_customer_token}`
          }
        }
      }).then((data) => {
        localStorage.setItem('current_group_id', data.data.customer.group_id)
        this.AAlogged(true)
        if (this.$cookies.isKey('lgpd_cookies')) {
          this.consentLGPD()
        }
        this.NameCustomer(`${data.data.customer.firstname}`)
      }).catch(({ graphQLErrors }) => {
      })
    },
    async consentLGPD() {
      await this.$apollo.mutate({
        mutation: lgpdLogConsent,
        variables: {
          groups: 1
        },
        context: {
          headers: {
            authorization: `bearer ${localStorage.current_customer_token}`
          }
        }
      }).then((data) => {
      }).catch(({ graphQLErrors }) => {
      })
    }
  }
}
